import React, { useEffect, useState } from 'react';

import Text from 'components/v2/core/Text';

import modulr from 'helpers/modulr';
import { TFetchSecureCardDetailResult } from 'helpers/modulr/modulr.types';

import CardPinLoading from './CardPinLoading';
import { TModulrCardPinProps } from '../useCardPin.types';

function ModulrCardPin({ url, token }: TModulrCardPinProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<TFetchSecureCardDetailResult['pin']>();

  useEffect(() => {
    getModulrCardPin();
  }, []);

  async function getModulrCardPin() {
    setLoading(true);
    const modulrCardData = await modulr.fetchSecureCardDetail(url, token);

    if (modulrCardData) {
      setPin(modulrCardData.pin);
    }
    setLoading(false);
  }

  if (loading || !pin) {
    return <CardPinLoading />;
  }

  return (
    <Text variant="titleSection" textAlign="center">
      {pin}
    </Text>
  );
}

export default ModulrCardPin;
