import React from 'react';

import Box from 'components/core/Box';
import Shimmer from 'components/core/Shimmer';

import { ONLY_VALUE_MIN_HEIGHT, WITH_DESCRIPTION_MIN_HEIGHT } from '../InfoItem.constants';
import { TInfoItemShimmerProps } from '../InfoItem.types';

function InfoItemShimmer({
  count = 1,
  hasDescription,
  hasValue = true,
  marginBottom = 'none',
}: TInfoItemShimmerProps) {
  const minHeight = hasDescription ? WITH_DESCRIPTION_MIN_HEIGHT : ONLY_VALUE_MIN_HEIGHT;

  return (
    <>
      {[...Array(count)].map((_, i) => (
        <Box
          key={i}
          minHeight={minHeight}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginBottom={count === i + 1 ? 'none' : marginBottom}>
          <Box flex={1}>
            <Shimmer shape="line" width="30%" height={14} />
            {hasDescription && <Shimmer shape="line" width="50%" height={14} mt="s" />}
          </Box>
          {hasValue && (
            <Box
              display="flex"
              alignSelf="stretch"
              justifyContent="flex-end"
              alignItems={hasDescription ? 'flex-end' : 'center'}
              width="40%"
              pb={hasDescription ? 's' : 'none'}
              minHeight={minHeight}>
              <Shimmer shape="line" width="40%" height={14} />
            </Box>
          )}
        </Box>
      ))}
    </>
  );
}

export default InfoItemShimmer;
