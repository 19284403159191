import * as Sentry from '@sentry/react';

import { TModulr, TModulrSecureCardDetailResponse } from './modulr.types';

async function fetchSecureCardDetail(url: string, token: string) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return undefined;
    }
    const responseJson: TModulrSecureCardDetailResponse = await response.json();

    return {
      pan: responseJson.pan,
      cvv: responseJson.cvv2,
      pin: responseJson.pin,
    };
  } catch (e) {
    Sentry.captureException(e);
    return undefined;
  }
}

const modulr: TModulr = {
  fetchSecureCardDetail,
};

export default modulr;
