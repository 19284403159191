import React, { useState } from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Pressable from 'components/v2/core/Pressable';
import { FileCheck03Icon, Copy07Icon } from 'components/v2/Icons';

import useTimeout from 'hooks/useTimeout';

import { COPY_ITEM_MIN_HEIGHT } from './CopyInfoItem.constants';
import { getCopyInfoItemStyle } from './CopyInfoItem.helpers';
import { TCopyInfoItemProps } from './CopyInfoItem.types';

function CopyInfoItem({ labelId, labelProps, value, onCopied, marginBottom }: TCopyInfoItemProps) {
  const [copied, setCopy] = useState(false);
  const [startTimeout] = useTimeout({
    delay: 750,
    callback: () => setCopy(false),
    autoStart: false,
  });
  const copyInfoItemStyle = getCopyInfoItemStyle({ copied });
  const Icon = copied ? FileCheck03Icon : Copy07Icon;

  async function handleCopy() {
    try {
      if (navigator.clipboard) {
        setCopy(true);
        startTimeout(true);
        await navigator.clipboard.writeText(value);
        onCopied?.();
      }
    } catch (e) {
      // no log
    }
  }

  return (
    <Box marginBottom={marginBottom}>
      <Pressable onClick={handleCopy}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          minHeight={COPY_ITEM_MIN_HEIGHT}>
          <Box flex={1}>
            <Text textId={labelId} variant="textBodySub" color="textSecondary" {...labelProps} />
            <Text variant="textBodySubBold" mt="xxs" color={copyInfoItemStyle.valueColor}>
              {value}
            </Text>
          </Box>
          <Box display="flex" alignSelf="flex-end" flexDirection="row" alignItems="center">
            <Text
              textId={copied ? 'label.copied' : 'label.copy'}
              variant="textBodySub"
              color={copyInfoItemStyle.iconColor}
              mr="xs"
            />
            <Icon iconColor={copyInfoItemStyle.iconColor} />
          </Box>
        </Box>
      </Pressable>
    </Box>
  );
}

export default CopyInfoItem;
