import React, { JSX, useState } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import normalizeUrl from 'helpers/normalizeUrl';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TCard, TCardTokenResponse } from 'types/card';
import CardProvider from 'enums/CardProvider';

import EnfuceCardPin from './views/EnfuceCardPin';
import ModulrCardPin from './views/ModulrCardPin';
import { TGetContentResult, TUseCardPinResult } from './useCardPin.types';

function useCardPin(card: TCard): TUseCardPinResult {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { post: requestCardPinToken, response } = useFetch(
    normalizeUrl(API_ENDPOINT.CARD_PIN_TOKEN, { pathVariables: { cardId: card?.id } })
  );

  async function getContent({ password }: { password: string }): Promise<TGetContentResult> {
    try {
      setLoading(true);
      const data = await requestCardPinToken({ password });

      if (!response.ok) {
        return { errorMessage: data?.errorMessage, content: undefined };
      }

      const { provider, url, token } = data.result as TCardTokenResponse;
      return {
        errorMessage: undefined,
        content: renderContent(provider, url, token),
      };
    } catch (e) {
      Sentry.captureException(e);
      return { errorMessage: t('errors.unexpectedError'), content: undefined };
    } finally {
      setLoading(false);
    }
  }

  function renderContent(provider: CardProvider, url: string, token: string): JSX.Element {
    switch (provider) {
      case CardProvider.ENFUCE:
        return <EnfuceCardPin url={url} token={token} />;
      case CardProvider.MODULR:
        return <ModulrCardPin url={url} token={token} />;
      default:
        return <></>;
    }
  }

  return {
    loading,
    getContent,
  };
}

export default useCardPin;
