import React, { cloneElement, isValidElement } from 'react';
import { isNil } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { ONLY_VALUE_MIN_HEIGHT, WITH_DESCRIPTION_MIN_HEIGHT } from './InfoItem.constants';
import { TInfoItemProps } from './InfoItem.types';

function InfoItem({
  labelId,
  labelProps,
  descriptionId,
  descriptionProps,
  value,
  valueProps,
  marginBottom,
}: TInfoItemProps) {
  const minHeight = descriptionId ? WITH_DESCRIPTION_MIN_HEIGHT : ONLY_VALUE_MIN_HEIGHT;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      minHeight={minHeight}
      marginBottom={marginBottom}>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        mr="s"
        alignSelf="stretch"
        justifyContent="center"
        minHeight={minHeight}>
        <Text textId={labelId} variant="textBodySub" color="textSecondary" {...labelProps} />
        {descriptionId && (
          <Text textId={descriptionId} variant="textBodySubBold" mt="xxs" {...descriptionProps} />
        )}
      </Box>
      {!isNil(value) && (
        <Box display="flex" alignSelf="stretch" alignItems={descriptionId ? 'flex-end' : 'center'}>
          {isValidElement(value) ? (
            <Box alignSelf="flex-end">{cloneElement(value)}</Box>
          ) : (
            <Text variant={descriptionId ? 'textBodyBold' : 'textBodySubBold'} {...valueProps}>
              {value}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
}

export default InfoItem;
