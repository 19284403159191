import React from 'react';

import Box from 'components/core/Box';
import { TextShimmer } from 'components/v2/core/Text';

function CardPinLoading() {
  return (
    <Box alignItems="center">
      <TextShimmer variant="titleSection" width="30%" />
    </Box>
  );
}

export default CardPinLoading;
