import React from 'react';

import EnfuceIframe from 'components/EnfuceIframe';

import { TEnfuceCardDataProps } from '../useCardData.types';
import { CARD_DATA_CONTENT_HEIGHT } from '../useCardData.constants';
import CardDataLoading from './CardDataLoading';

function EnfuceCardData({ url, token }: TEnfuceCardDataProps) {
  return (
    <EnfuceIframe
      title="Card data"
      url={url}
      messageBody={{ operation: 'view-pan', token }}
      height={CARD_DATA_CONTENT_HEIGHT}
      renderLoading={CardDataLoading}
    />
  );
}

export default EnfuceCardData;
