import React from 'react';

import EnfuceIframe from 'components/EnfuceIframe';

import { TEnfuceCardPinProps } from '../useCardPin.types';
import { PIN_CONTENT_HEIGHT } from '../useCardPin.constants';
import CardPinLoading from './CardPinLoading';

function EnfuceCardPin({ url, token }: TEnfuceCardPinProps) {
  return (
    <EnfuceIframe
      title="Card pin"
      url={url}
      messageBody={{ operation: 'view-pin', controlId: token }}
      height={PIN_CONTENT_HEIGHT}
      renderLoading={CardPinLoading}
    />
  );
}

export default EnfuceCardPin;
