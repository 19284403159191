import React from 'react';

import Box from 'components/core/Box';
import { CopyInfoItemShimmer } from 'components/v2/core/CopyInfoItem';
import { InfoItemShimmer } from 'components/v2/core/InfoItem';

import { CARD_DATA_CONTENT_HEIGHT } from '../useCardData.constants';

function CardDataLoading() {
  return (
    <Box width="100%" height={CARD_DATA_CONTENT_HEIGHT} flexDirection="column" gap="m">
      <CopyInfoItemShimmer />
      <Box mt="m" display="flex" flexDirection="row" gap="xxl">
        <InfoItemShimmer count={2} hasValue={false} hasDescription />
      </Box>
    </Box>
  );
}

export default CardDataLoading;
