import React, { useImperativeHandle, useRef, useState } from 'react';

import PageModal from 'components/PageModal';
import PasswordForm from 'components/Form/PasswordForm';
import Box from 'components/core/Box';
import CardInfoModal from 'modals/CardInfoModal';
import CardItem from 'components/v2/CardItem';

import { useModal } from 'components/core/Modal';
import {
  TPasswordFormData,
  TPasswordFormRef,
} from 'components/Form/PasswordForm/PasswordForm.types';
import { TCardInfoModalRef } from 'modals/CardInfoModal/CardInfoModal.types';
import useCardData from 'hooks/useCardData';
import { TCard } from 'types/card';
import { showErrorAlert } from 'helpers/showAlertModal';

import {
  TCardInfoAuthenticationModalProps,
  TModalState,
} from './CardInfoAuthenticationModal.types';

function CardInfoAuthenticationModal({ modalRef }: TCardInfoAuthenticationModalProps) {
  const ref = useModal();
  const cardInfoModalRef = useRef<TCardInfoModalRef>(null);
  const [state, setState] = useState<TModalState>();
  const formRef = useRef<TPasswordFormRef>(null);
  const { getContent, loading } = useCardData(state?.card as TCard);

  useImperativeHandle(modalRef, () => ({
    show: (state) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  async function handleCardInfoFlow({ password }: TPasswordFormData) {
    const result = await getContent({ password });
    if (!result.content) {
      showErrorAlert({
        title: result.errorMessage,
      });
      formRef.current?.clear();
      return;
    }

    cardInfoModalRef.current?.show({
      card: state?.card as TCard,
      cardDataContent: result.content,
      onClose: () => ref.current?.show(false),
    });
    formRef.current?.clear();
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId="screens.headerTitle.cardInfoAuthentication"
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}>
        {state?.card && (
          <>
            <PasswordForm
              ref={formRef}
              onSubmit={handleCardInfoFlow}
              descriptionId="screens.cardInfoAuthentication.description"
              loading={loading}>
              <Box mb="l">
                <CardItem card={state.card} />
              </Box>
            </PasswordForm>
          </>
        )}
      </PageModal>
      <CardInfoModal modalRef={cardInfoModalRef} />
    </>
  );
}

export default CardInfoAuthenticationModal;
