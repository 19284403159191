import React, { useState } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { TCard, TCardTokenResponse } from 'types/card';
import normalizeUrl from 'helpers/normalizeUrl';
import API_ENDPOINT from 'constants/apiEndpoint';
import CardProvider from 'enums/CardProvider';

import EnfuceCardData from './views/EnfuceCardData';
import ModulrCardData from './views/ModulrCardData';
import { TGetContentResult, TUseCardDataResult } from './useCardData.types';

function useCardData(card: TCard): TUseCardDataResult {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { post: requestCardDataToken, response } = useFetch(
    normalizeUrl(API_ENDPOINT.CARD_DATA_TOKEN, { pathVariables: { cardId: card?.id } })
  );

  async function getContent({ password }: { password: string }): Promise<TGetContentResult> {
    try {
      setLoading(true);
      const data = await requestCardDataToken({ password });
      if (!response.ok) {
        return { errorMessage: data?.errorMessage, content: undefined };
      }

      const { provider, url, token } = data.result as TCardTokenResponse;
      return {
        errorMessage: undefined,
        content: renderContent(provider, url, token),
      };
    } catch (e) {
      Sentry.captureException(e);
      return { errorMessage: t('errors.unexpectedError'), content: undefined };
    } finally {
      setLoading(false);
    }
  }

  function renderContent(provider: CardProvider, url: string, token: string) {
    switch (provider) {
      case CardProvider.ENFUCE:
        return <EnfuceCardData url={url} token={token} />;
      case CardProvider.MODULR:
        return <ModulrCardData url={url} token={token} expiryDate={card.expiryDate} />;
      default:
        return <></>;
    }
  }

  return {
    loading,
    getContent,
  };
}

export default useCardData;
