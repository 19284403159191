import React, { useEffect, useState } from 'react';
import masks from '@wamoio/common/lib/masks';

import Box from 'components/core/Box';
import CopyInfoItem from 'components/v2/core/CopyInfoItem';
import InfoItem from 'components/v2/core/InfoItem';

import modulr from 'helpers/modulr';
import { TFetchSecureCardDetailResult } from 'helpers/modulr/modulr.types';
import { showErrorAlert } from 'helpers/showAlertModal';
import { TI18nId } from 'types/common';

import { TModulrCardDataProps } from '../useCardData.types';
import { CARD_DATA_CONTENT_HEIGHT } from '../useCardData.constants';
import CardDataLoading from './CardDataLoading';

function ModulrCardData({ url, token, expiryDate }: TModulrCardDataProps) {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState<Pick<TFetchSecureCardDetailResult, 'pan' | 'cvv'>>();

  useEffect(() => {
    getModulrCardData();
  }, []);

  async function getModulrCardData() {
    setLoading(true);
    const modulrCardData = await modulr.fetchSecureCardDetail(url, token);

    if (modulrCardData) {
      setCardData({
        pan: modulrCardData.pan,
        cvv: modulrCardData.cvv,
      });
    } else {
      showErrorAlert({ titleId: 'label.unexpectedError' });
    }
    setLoading(false);
  }

  if (loading || !cardData) {
    return <CardDataLoading />;
  }

  return (
    <Box height={CARD_DATA_CONTENT_HEIGHT}>
      <CopyInfoItem labelId="screens.cardInfo.cardNo" value={masks.cardNumber.mask(cardData.pan)} />
      <Box alignment="row.horizontalCenter" gap="xxl" mt="m">
        <InfoItem labelId="screens.cardInfo.cvv" descriptionId={cardData.cvv as TI18nId} />
        <InfoItem labelId="screens.cardInfo.expiryDate" descriptionId={expiryDate as TI18nId} />
      </Box>
    </Box>
  );
}

export default ModulrCardData;
