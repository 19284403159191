import React, { useRef, useState } from 'react';

import Box from 'components/core/Box';

import { TEnfuceIframeProps } from './EnfuceIframe.types';
import { StyledIframe } from './EnfuceIframe.styles';

function EnfuceIframe({ title, url, messageBody, height, renderLoading }: TEnfuceIframeProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  function handleLoad() {
    setLoading(false);
    iframeRef.current?.contentWindow?.postMessage(messageBody, url);
  }

  return (
    <Box height={height}>
      {loading && renderLoading()}
      <StyledIframe
        title={title}
        height={height}
        src={url}
        ref={iframeRef}
        onLoad={handleLoad}
        display={loading ? 'none' : 'block'}
      />
    </Box>
  );
}

export default EnfuceIframe;
